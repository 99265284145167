import { i18n } from '@/main.js';

export const getPersonInformation = (persons, user) => {
  const { t } = i18n.global;

  const REQUEST_CONNECT_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2
  };

  const result = persons.map((person) => {
    let label;

    let replacePersonWithUserData = false;
    let isAllowToShowCode = false;

    const requestConnect = person.requestConnectCustomer.sort((a, b) => b.id - a.id)[0];

    if (requestConnect?.status === REQUEST_CONNECT_STATUS.ACCEPTED && requestConnect?.user?.id === user.id) {
      label = t('you');
      replacePersonWithUserData = true;
    } else if (
      requestConnect?.status === REQUEST_CONNECT_STATUS.ACCEPTED &&
      requestConnect?.user?.id !== user.id &&
      requestConnect?.user?.active &&
      !requestConnect?.user?.is_deleted
    ) {
      label = t('user_connected');
    } else if (
      requestConnect?.status === REQUEST_CONNECT_STATUS.ACCEPTED &&
      requestConnect?.user?.id !== user.id &&
      (!requestConnect?.user?.active || requestConnect?.user?.is_deleted)
    ) {
      label = t('user_inactive');
    } else if (requestConnect?.status === REQUEST_CONNECT_STATUS.PENDING) {
      label = t('pending');
      isAllowToShowCode = true;
    } else if (requestConnect?.status === REQUEST_CONNECT_STATUS.REJECTED) {
      label = t('rejected');
    } else if (!person.user_id) {
      label = t('no_user_connected');
    } else {
      label = null;
    }

    // IF request connect status is not exist check person <-> user relation
    if (!requestConnect && person.User) {
      if (person.User.id === user.id) {
        label = t('you');
      } else if (person.User.active && !person.User.is_deleted) {
        label = t('user_connected');
      } else if (!person.User.active || person.User.is_deleted) {
        label = t('user_inactive');
      } else {
        label = t('no_user_connected');
      }
    }

    return {
      ...person,
      first_name: replacePersonWithUserData ? user.first_name : person.first_name,
      last_name: replacePersonWithUserData ? user.last_name : person.last_name,
      phone: replacePersonWithUserData ? user.mobile : person.phone,
      email: replacePersonWithUserData ? user.email : person.email,
      requestConnectId: requestConnect?.id,
      code: requestConnect?.code,
      valid_to: requestConnect?.valid_to,
      label,
      isAllowToShowCode: requestConnect?.code ? isAllowToShowCode : false,
      is_expired: requestConnect?.is_expired
    };
  });

  return result;
};
